import { View, Text } from "@react-pdf/renderer";
import styles from "./styles";

const Bullet = ({ text, style }) => {
  return (
    <View style={{ ...styles.bulletPoint, ...style }}>
      <Text style={styles.bullet}>·</Text>
      <Text style={styles.bulletText}>{text}</Text>
    </View>
  );
};

export default Bullet;
