import { useMemo, useState } from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import Letter from "./letter";

const preDefinedText = `I am excited to apply for this Product Design position at Unity. With over 10 years of experience as a UI/UX designer and front-end developer, I am confident in my ability to create intuitive and engaging user experiences for Unity's products.

I have designed UI/UX for a variety of projects, including mobile apps and web platforms. In my current position as Principal UX designer at Eidos, I am responsible for an application allowing game configuration, monitoring, and general backend utilities used by AAA games across multiple studios, like Marvel Avengers and Guardians of the Galaxy, so it is crucial to me to create designs that are both visually appealing and intuitive as well as user-friendly.

In addition to my design skills, I have also worked for years as a front-end developer. This experience has given me a unique perspective on the design process, allowing me to create designs that are not only visually captivating but also technically feasible. So I am used to taking accountability in not only creating the design but seeing it through and developing the front-end code for it.

Last but not least, over the course of my career, strategical product thinking has become second nature to me: I believe a more holistic comprehension of the entire product strategy makes me a better product designer and enables me to suggest improvements that not only make sense from a feature's UX/UI perspective, but also from the perspective of the product as a whole and its audience.

I am excited about the opportunity to bring my skills and experience to Unity, a company that is at the forefront of innovation in the gaming industry. I am confident that my passion for design, attention to detail, and technical expertise will make me a valuable addition to your team.

Thank you for considering my application. I look forward to the opportunity to discuss my qualifications further.
`;

const CoverLetterForm = ({ company, header }) => {
  const today = new Date().toLocaleDateString("en-CA", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const [date, setDate] = useState(today);
  const [recipient, setRecipient] = useState("Software Team Hiring Manager");
  const [letterText, setLetterText] = useState(preDefinedText);

  const paragraphs = useMemo(() => {
    return letterText.split("\n\n");
  }, [letterText]);

  const letter = (
    <Letter
      date={date}
      paragraphs={paragraphs}
      recipient={recipient}
      company={company}
    />
  );

  return (
    <>
      <main>
        { header }
        <form>
          <div className="formitem">
            <label>Date:</label>
            <input
              type="text"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div className="formitem">
            <label>To:</label>
            <input
              type="text"
              value={recipient}
              onChange={(e) => setRecipient(e.target.value)}
            />
          </div>
          <div className="formitem">
            <label>Text:</label>
            <textarea
              type="text"
              value={letterText}
              onChange={(e) => setLetterText(e.target.value)}
            />
          </div>
          <PDFDownloadLink
            className="download"
            document={letter}
            fileName={
              company
                ? `raphael-aleixo_cover-letter_${company.replaceAll(' ', '').toLowerCase()}.pdf`
                : "raphael-aleixo_cover-letter.pdf"
            }
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download now!"
            }
          </PDFDownloadLink>
        </form>
      </main>
      <div className="frame">
        {paragraphs.length > 0 ? (
          <PDFViewer showToolbar={false}>{letter}</PDFViewer>
        ) : null}
      </div>
    </>
  );
};

export default CoverLetterForm;
