import React from "react";
import JsxParser from "react-jsx-parser";
import {
  Document,
  Page,
  Text,
  View,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";
import styles from "./styles";
import SectionTitle from "./sectionTitle";
import Bullet from "./bullet";

Font.register({
  family: "Bitter",
  fonts: [
    { src: "/fonts/NoticiaText-Regular.ttf" }, // font-style: normal, font-weight: normal
    { src: "/fonts/NoticiaText-Italic.ttf", fontStyle: "italic" },
    { src: "/fonts/NoticiaText-Bold.ttf", fontWeight: "bold" },
  ],
});
Font.register({
  family: "Codec",
  src: "/fonts/Gantari-Black.ttf",
});

Font.registerHyphenationCallback((word) => [word]);

const replaceText = (text) => {
  const finalText = text
    .replace(
      /\*\*(\S(.*?\S)?)\*\*/gm,
      '<Text style={{fontWeight: "bold"}}>$1</Text>'
    )
    .replace(
      /(--\s)(\S(.*?\S)?)(\s--)/gm,
      "<Bullet text={<Text>$2</Text>} key={'$2'}/>"
    );
  return finalText;
};

const Curriculum = ({
  experiences,
  intro,
  tools,
  company,
  contacts,
  title,
}) => {
  return (
    <Document
      title={
        company
          ? `raphael-aleixo_cv_${company.toLowerCase()}.pdf`
          : "raphael-aleixo_cv.pdf"
      }
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.cover} wrap={false}>
          <View style={styles.coverright}>
            <Text style={styles.coverTitle}>Raphael Aleixo</Text>
            <Text style={styles.coverSubTitle}>{title}</Text>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {contacts.map((item, index) => (
                <Bullet
                  key={item.type}
                  style={{ width: index % 2 === 0 ? "40%" : "60%" }}
                  text={
                    <Text>
                      <Text
                        style={{ fontWeight: "bold", marginRight: 5 }}
                      >{`${item.type}: `}</Text>
                      <Text>{item.info}</Text>
                    </Text>
                  }
                />
              ))}
            </View>
            {intro ? (
              <Text style={styles.coverIntro}>
                <JsxParser
                  bindings={{ styles }}
                  components={{ Text }}
                  jsx={`<Text>${replaceText(intro)}</Text>`}
                />
              </Text>
            ) : null}
            <SectionTitle title="Relevant Experience" />
            {experiences.map((experience) => {
              const jobText = replaceText(experience.jobDescription);
              return (
                <View style={{ marginBottom: 12 }}>
                  <Text style={[styles.job, styles.jobTitle]}>
                    {`${experience.title} at ${experience.company} (${experience.time})`}
                  </Text>
                  <Text style={[styles.job, styles.companyDescription]}>
                    {experience.companyDescription}
                  </Text>
                  <JsxParser
                    bindings={{ styles }}
                    renderInWrapper={false}
                    components={{ Text, Bullet }}
                    jsx={
                      jobText.startsWith("<")
                        ? jobText
                        : `<Text>${jobText}</Text>`
                    }
                  />
                </View>
              );
            })}
          </View>
          <View style={styles.coverleft}>
            <Svg style={styles.image} viewBox="0 0 64 38">
              <Path
                fill="#091619"
                fillRule="evenodd"
                d="m59.502 31.504-1.09-8.788 6.006-6.464-8.628-1.671-4.242-7.755-4.242 7.755-4.762.922L34.998 0h-6.83L9.779 38.854h10.508c7.959-9.632 17.501-12.282 23.988-12.838l-.337 2.718a34.6 34.6 0 0 0-4.941.908l4.408 9.212h10.508l-4.84-9.941 2.476-1.171 7.953 3.762zm-31.244-8.95 3.587-7.853 2.439 5.099a39.448 39.448 0 0 0-6.026 2.754z"
                clipRule="evenodd"
              />
            </Svg>
            <View>
              <SectionTitle title="Skills" />
              {tools.map((item) => (
                <View key={item.key} style={styles.sidebarItem}>
                  <View style={styles.sidebarTitle}>
                    <Text style={styles.sidebarTitleText}>{item.title}</Text>
                  </View>
                  <Text>{item.content}</Text>
                </View>
              ))}
            </View>
            <View>
              <SectionTitle title="Education" />
              <View style={styles.sidebarItem}>
                <Text style={{ fontWeight: "bold" }}>
                  Bachelor, Graphic Design
                </Text>
                <Text>Centro Universitário da Cidade</Text>
                <Text>Rio de Janeiro, Brazil</Text>
              </View>
            </View>
            <View>
              <SectionTitle title="Languages" />
              <View style={styles.sidebarItem}>
                <Bullet
                  text={
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>Portuguese:</Text>{" "}
                      Native
                    </Text>
                  }
                />
                <Bullet
                  text={
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>English:</Text>{" "}
                      Fluent
                    </Text>
                  }
                />
                <Bullet
                  text={
                    <Text>
                      <Text style={{ fontWeight: "bold" }}>French:</Text>{" "}
                      Professional proficiency
                    </Text>
                  }
                />
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Curriculum;
