import React, { useState } from "react";
import CurriculumForm from "./cv/curriculumForm";
import Header from "./header";
import CoverLetterForm from "./letter/letterForm";

function App() {
  const [mode, setMode] = useState("cv");
  const [company, setCompany] = useState("");

  const header = (
    <Header company={company} mode={mode} setMode={setMode} setCompany={setCompany} />
  )

  return (
    <div className="app">
      <div className="wrapper">
        {mode === "cv" ? (
          <CurriculumForm company={company} header={header} />
        ) : (
          <CoverLetterForm company={company} header={header} />
        )}
      </div>
    </div>
  );
}

export default App;
