import { Text, Svg, Line } from "@react-pdf/renderer";
import styles from "./styles";

const SectionTitle = ({ title, number, noMargin }) => (
  <>
    <Text style={[styles.sectionTitle, noMargin ? { marginTop: 0 } : null]}>
      {number ? `0${number}. ${title}` : title}
    </Text>
    <Svg style={styles.sectionTitleBar} width={17} height={6}>
      <Line x1={0} x2={17} y1={0} y2={0} stroke="black" strokeWidth={6} />
    </Svg>
  </>
);

export default SectionTitle;
