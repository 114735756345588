const Header = ({ company, setCompany, mode, setMode }) => (
  <header>
    <h1>Job Hunt</h1>
    <div>
      <input
        type="text"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
        placeholder="For which company?"
      />
    </div>
    <div>
      <div>
        <input
          type="radio"
          id="cv"
          onChange={() => setMode("cv")}
          checked={mode === "cv"}
        />
        <label htmlFor="cv">CV</label>
      </div>
      <div>
        <input
          type="radio"
          id="letter"
          onChange={() => setMode("letter")}
          checked={mode === "letter"}
        />
        <label htmlFor="letter">Cover Letter</label>
      </div>
    </div>
  </header>
);

export default Header;
