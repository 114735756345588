import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    border: 4,
    borderColor: "#20bf55",
    padding: 40,
    color: "#091619",
    fontFamily: "Bitter",
    fontSize: 7.5,
    lineHeight: 1.4,
  },
  section: {
    flexDirection: "column",
    flexWrap: "wrap",
    gap: 16,
    maxHeight: "100%",
    alignContent: "space-between",
  },
  cover: {
    flexDirection: "row",
    gap: 16,
    alignItems: "flex-start",
    alignContent: "center",
    justifyContent: "space-between",
  },
  coverleft: {
    width: "30%"
  },
  coverright: {
    width: "65%"
  },
  coverTitle: {
    fontSize: 26,
    fontFamily: "Codec",
    letterSpacing: "-0.6pt",
  },
  coverSubTitle: {
    fontSize: 10,
    fontFamily: "Codec",
    textTransform: "uppercase",
    color: "#39b556",
    lineHeight: 1,
    marginTop: -5,
    marginBottom: 8,
  },
  coverIntro: {
    marginVertical: 10,
  },
  job: {
  },
  jobTitle: {
    fontFamily: "Codec",
    fontSize: 9,
    textTransform: "uppercase",
    color: "#39b556",
    lineHeight: 1,
  },
  companyDescription: {
    marginVertical: 4,
    color: "#25408f",
    fontStyle: "italic",
    lineHeight: 1.2,
  },
  removeMargin: {
    marginTop: -10,
    marginBottom: -16,
  },
  jobDescription: {
    marginTop: -4,
    marginBottom: 8,
  },
  sectionTitle: {
    fontFamily: "Codec",
    fontSize: 11,
    textTransform: "uppercase",
    marginTop: 12,
  },
  sectionTitleBar: {
    marginBottom: 4,
  },
  bulletPoint: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 4,
  },
  bullet: {
    color: "#39b556",
    width: 6,
    fontWeight: "bold",
    fontSize: 12,
    marginTop: -3,
    marginBottom: -3,
  },
  bulletText: {
    flex: 1,
  },
  image: {
    display: "block",
    width: 40,
    height: 40,
    marginBottom: 18,
    marginTop: -7,
    marginLeft: -5,
  },
  sidebarItem:  {
    marginBottom: 15,
    lineHeight: 1.6,
  },
  sidebarImage: {
    width: 12,
    height: 12,
    marginRight: 5,
  },
  sidebarTitle: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    lineHeight: 1,
    marginBottom: 5,
  },
  sidebarTitleText: {
    fontFamily: "Codec",
    textTransform: "uppercase",
    color: "#25408f",
    fontSize: 9,
    lineHeight: 1.2,
  }
});

export default styles;
