import React, { useState, useEffect, useMemo } from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import Curriculum from "./curriculum";
import { fetchFromSheets, sortExperiences } from "../utils";

const CurriculumForm = ({ company, header }) => {
  const [experiences, setExperiences] = useState([]);
  const [selectedExperiences, setSelectedExperiences] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);
  const [selectedIntro, setSelectedIntro] = useState();
  const [intro, setIntro] = useState("");
  const [title, setTitle] = useState("PRODUCT DESIGNER + CREATIVE FRONT-END DEVELOPER");
  const [introTypes, setIntroTypes] = useState([]);
  const [tools, setTools] = useState([]);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const toolsArray = await fetchFromSheets("tools");
      const introArray = await fetchFromSheets("summary");
      const contactsArray = await fetchFromSheets("contacts");
      const experienceBullets = await fetchFromSheets("experience2");
      setExperiences(experienceBullets);
      setIntroTypes(introArray);
      setIntro(introArray[0]?.summary);
      setTools(toolsArray);
      setContacts(contactsArray);
    };
    fetchData();
  }, []);

  const setItem = (e, state, func) => {
    const {
      target: { value },
    } = e;
    if (state.includes(value)) {
      func(state.filter((item) => item !== value));
    } else {
      func([...state, value]);
    }
  };

  const filteredExperiences = useMemo(() => {
    return experiences
      .filter((experience) => selectedExperiences.includes(experience.key))
      .sort(sortExperiences);
  }, [selectedExperiences, experiences]);

  const filteredTools = useMemo(() => {
    return tools
      .filter((sumItem) => selectedTools.includes(sumItem.key))
      .sort(sortExperiences);
  }, [selectedTools, tools]);

  const curriculum = (
    <Curriculum
      company={company}
      experiences={filteredExperiences}
      intro={intro}
      tools={filteredTools}
      contacts={contacts}
      title={title}
    />
  );

  return (
    <>
      <main>
        {header}
        <form>
          <div className="formitem">
            <label>Intro:</label>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
            <select
              value={selectedIntro?.key}
              onChange={(e) => {
                const targetIntro = introTypes.find(
                  (item) => item.key === e.target.value
                );
                setSelectedIntro(targetIntro);
                setIntro(targetIntro.summary);
                console.log(targetIntro);
              }}
            >
              {introTypes.map((option) => (
                <option key={option.key} value={option.key}>
                  {option.key}
                </option>
              ))}
            </select>
            <textarea
              value={intro}
              onChange={(e) => setIntro(e.target.value)}
            />
          </div>
          <div className="formitem">
            <label>Relevant experience:</label>
            <div className="checkboxes">
              {experiences.sort(sortExperiences).map((experience) => {
                return (
                  <div key={experience.key}>
                    <input
                      type="checkbox"
                      id={experience.key}
                      value={experience.key}
                      checked={selectedExperiences.includes(experience.key)}
                      onChange={(e) =>
                        setItem(e, selectedExperiences, setSelectedExperiences)
                      }
                    />
                    <label htmlFor={experience.key}>{experience.key}</label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="formitem">
            <label>Skills bulletpoints:</label>
            <div className="checkboxes">
              {tools.sort(sortExperiences).map((sumItem) => {
                return (
                  <div key={sumItem.key}>
                    <input
                      type="checkbox"
                      id={sumItem.key}
                      value={sumItem.key}
                      checked={selectedTools.includes(sumItem.key)}
                      onChange={(e) =>
                        setItem(e, selectedTools, setSelectedTools)
                      }
                    />
                    <label htmlFor={sumItem.key}>{sumItem.key}</label>
                  </div>
                );
              })}
            </div>
          </div>
          <PDFDownloadLink
            className="download"
            document={curriculum}
            fileName={
              company
                ? `raphael-aleixo_cv_${company
                    .replaceAll(" ", "")
                    .toLowerCase()}.pdf`
                : "raphael-aleixo_cv.pdf"
            }
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download now!"
            }
          </PDFDownloadLink>
        </form>
      </main>
      <div className="frame">
        {experiences.length > 0 ? (
          <PDFViewer showToolbar={false}>{curriculum}</PDFViewer>
        ) : null}
      </div>
    </>
  );
};

export default CurriculumForm;
