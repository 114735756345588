import {
  Page,
  Text,
  Document,
  StyleSheet,
  Svg,
  Path,
  Font,
  Image,
} from "@react-pdf/renderer";

Font.register({
  family: "Bitter",
  src: "/pdf-fonts/Bitter-Regular.ttf",
});

Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  page: {
    border: 10,
    borderColor: "#20bf55",
    padding: 80,
    paddingLeft: 200,
    color: "#091619",
    fontFamily: "Bitter",
  },
  image: {
    display: "block",
    width: 40,
    height: 40,
    marginBottom: 10,
    marginLeft: -5,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  text: {
    fontSize: 9,
    lineHeight: 1.6,
    marginVertical: 5,
  },
  signature: {
    height: 30,
    width: 54,
    marginTop: 20,
  },
});

const Letter = ({ paragraphs = [], date, recipient, company }) => {
  return (
    <Document
      title={
        company
          ? `raphael-aleixo_cover-letter_${company}.pdf`
          : "raphael-aleixo_cover-letter.pdf"
      }
    >
      <Page style={styles.page}>
        <Svg style={styles.image} viewBox="0 0 64 38">
          <Path
            fill="#091619"
            fillRule="evenodd"
            d="m59.502 31.504-1.09-8.788 6.006-6.464-8.628-1.671-4.242-7.755-4.242 7.755-4.762.922L34.998 0h-6.83L9.779 38.854h10.508c7.959-9.632 17.501-12.282 23.988-12.838l-.337 2.718a34.6 34.6 0 0 0-4.941.908l4.408 9.212h10.508l-4.84-9.941 2.476-1.171 7.953 3.762zm-31.244-8.95 3.587-7.853 2.439 5.099a39.448 39.448 0 0 0-6.026 2.754z"
            clipRule="evenodd"
          />
        </Svg>
        <Text style={styles.text}>{date}</Text>
        <Text style={styles.text}>{`Dear ${recipient} at ${company},`}</Text>
        {paragraphs.map((paragraph) => (
          <Text style={styles.text} key={paragraph}>
            {paragraph}
          </Text>
        ))}
        <Image style={styles.signature} src="images/signature.png" />
        <Text style={styles.text}>Raphael Aleixo</Text>
      </Page>
    </Document>
  );
};

export default Letter;
